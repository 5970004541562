var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{staticClass:"justify-content-between mb-3"},[_c('CCol',{attrs:{"col":"auto"}},[_c('CDropdown',{attrs:{"togglerText":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.modifyBtn'
          ),"color":"primary","disabled":!_vm.canView(_vm.PERMS.ORDERS_EDIT)}},[_c('CDropdownItem',{on:{"click":function($event){return _vm.setDelivered()}}},[_vm._v(_vm._s(_vm.$t( "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsDelivered" )))]),_c('CDropdownItem',{on:{"click":function($event){return _vm.setNotDelivered()}}},[_vm._v(_vm._s(_vm.$t( "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsNonDelivered" )))]),_c('CDropdownItem',{on:{"click":function($event){return _vm.createIncident(_vm.INCIDENT_TYPE.INCIDENT)}}},[_vm._v(_vm._s(_vm.$t("role.orders.manage.incident.modal.title.openIncident")))]),_c('CDropdownItem',{on:{"click":function($event){return _vm.createIncident(_vm.INCIDENT_TYPE.CANCELLATION)}}},[_vm._v(_vm._s(_vm.$t( "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.cancel" )))])],1)],1),_c('CCol',{attrs:{"col":"auto"}},[_c('OrdersPrints',{attrs:{"prints":_vm.ddmp.prints,"title":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.printBtn'
          ),"types":['producer_products']}})],1)],1),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center m-3"},[_c('CSpinner',{attrs:{"color":"info"}})],1):_vm._e(),_c('ejs-grid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"grid",attrs:{"dataSource":_vm.rows,"allowSelection":true,"selectionSettings":_vm.selectionSettings,"detailTemplate":_vm.detailTemplate,"dataBound":_vm.onDataBound},on:{"rowSelected":function($event){return _vm.rowSelectedHandler($event)},"rowDeselected":function($event){return _vm.rowDeselectedHandler($event)},"detailDataBound":_vm.onDetailDataBound}},[_c('e-columns',[_c('e-column',{attrs:{"type":"checkbox","width":"40","textAlign":"left"}}),_c('e-column',{attrs:{"field":"id","headerText":"","isPrimaryKey":"{true}","width":"40","template":_vm.countTemplate}}),_c('e-column',{attrs:{"field":"ppm","headerText":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.table.name'
          ),"template":_vm.nameTemplate}}),_c('e-column',{attrs:{"field":"ppm","headerText":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.table.orders'
          ),"template":_vm.ordersTemplate,"width":"100"}}),_c('e-column',{attrs:{"field":"ppm","headerText":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.table.uni'
          ),"width":"130","template":_vm.conservationTemplate}}),_c('e-column',{attrs:{"field":"price","textAlign":"right","headerText":_vm.$t(
            'role.orders.manage.ddmp.detail.delivery.table.products.table.price'
          ),"width":"100","template":_vm.priceTemplate}}),_c('e-column',{attrs:{"field":"actions","headerText":"","width":"45","template":_vm.actionsTemplate}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }